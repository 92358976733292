import { defaultLocale } from "../locales";
import { ModelCount } from "../types/ModelInfo";
import { BaseAction, PageData, PageType, RouteType } from "../types/site";
import { Vehicle, VehicleType } from "../types/vehicle";

export enum AnalyticsPageType {
  item = "item",
  itemlist = "itemlist",
  contact = "contact",
  directions = "directions",
  service = "service",
  parts = "parts",
  accessories = "accessories",
  body_shop = "body_shop",
  home = "home",
  build_to_order = "build_to_order",
  custom = "custom",
  complaint = "complaint",
  employment = "employment",
  recall = "recall",
  finance = "finance",
  credit = "credit",
  trade = "trade",
  commercial = "commercial",
  showroom = "showroom",
  error = "error",
}

export interface IFormEventData {
  page_type: AnalyticsPageType;
  comm_type: string;
  form_name: string;
  form_type: string;
}

const SALES_LEAD_DATE_CONFIG = "_$salesLeadDate";
const SERVICE_LEAD_DATE_CONFIG = "_$serviceLeadDate";
const PARTS_LEAD_DATE_CONFIG = "_$partsLeadDate";

export const SALES_LEAD_DATE_CHANGED_MESSAGE = "SALES_LEAD_DATE_CHANGED";
export const SERVICE_LEAD_DATE_CHANGED_MESSAGE = "SERVICE_LEAD_DATE_CHANGED";
export const PARTS_LEAD_DATE_CHANGED_MESSAGE = "PARTS_LEAD_DATE_CHANGED";
export const FORM_ENGAGEMENT_MESSAGE = "FORM_ENGAGEMENT";
const ANALITYCS_MESSAGE = "RML_ANALITYCS";

export const ANALYTICS_DEPARTMENTS = ["sales", "parts", "service"];

function handleAnaliticsData(eventData: any) {
  processPageType(eventData);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);
  if (window.asc_datalayer) {
    window.asc_datalayer.events.splice(0, 0, eventData);
  }
  if (eventData.event === "asc_form_submission_sales" || eventData.event === "asc_form_submission_sales_appt") {
    const curDate = new Date();
    if (window.asc_datalayer) {
      window.asc_datalayer.salesLeadDate = curDate;
      window.localStorage.setItem(SALES_LEAD_DATE_CONFIG, curDate.toISOString());
      window.dispatchEvent(new CustomEvent(SALES_LEAD_DATE_CHANGED_MESSAGE, { detail: curDate }));
    }
  }

  if (eventData.event === "asc_form_submission_service" || eventData.event === "asc_form_submission_service_appt") {
    const curDate = new Date();
    if (window.asc_datalayer) {
      window.asc_datalayer.serviceLeadDate = curDate;
      window.localStorage.setItem(SERVICE_LEAD_DATE_CONFIG, curDate.toISOString());
      window.dispatchEvent(new CustomEvent(SERVICE_LEAD_DATE_CHANGED_MESSAGE, { detail: curDate }));
    }
  }

  if (eventData.event === "asc_form_submission_parts") {
    const curDate = new Date();
    if (window.asc_datalayer) {
      window.asc_datalayer.partsLeadDate = curDate;
      window.localStorage.setItem(PARTS_LEAD_DATE_CONFIG, curDate.toISOString());
      window.dispatchEvent(new CustomEvent(PARTS_LEAD_DATE_CHANGED_MESSAGE, { detail: curDate }));
    }
  }

  if (eventData.event === "asc_form_engagement") {
    if (window.asc_datalayer) {
      window.dispatchEvent(new CustomEvent(FORM_ENGAGEMENT_MESSAGE));
    }
  }
}

let currentAnalyticsPageType = AnalyticsPageType.custom;
function processPageType(eventData: any) {
  if (eventData.event === "asc_pageview") {
    currentAnalyticsPageType = eventData.page_type;
  } else if (!eventData.page_type) {
    eventData.page_type = currentAnalyticsPageType;
  }
}

export function sendAnalyticsData(eventData: any) {
  if (window.parent === window) {
    handleAnaliticsData(eventData);
  } else {
    // event from iframe
    window.parent.postMessage({ type: ANALITYCS_MESSAGE, data: eventData });
  }
}

export function sendAnalyticsEvent(event: string, eventData: any) {
  sendAnalyticsData({ event, event_owner: "runmylease", ...eventData });
}

export function getAnalyticsPageType(page: PageData): AnalyticsPageType {
  if (page.pageType === PageType.SRP) {
    return AnalyticsPageType.itemlist;
  }
  if (page.pageType === PageType.VDP) {
    return AnalyticsPageType.item;
  }
  if (page.pagePath === "/") {
    return AnalyticsPageType.home;
  }

  if (page.pagePath.includes("service")) {
    return AnalyticsPageType.service;
  }

  if (page.pagePath.includes("financ")) {
    return AnalyticsPageType.finance;
  }

  if (page.pagePath.includes("credit")) {
    return AnalyticsPageType.credit;
  }

  if (page.pagePath.includes("contact")) {
    return AnalyticsPageType.contact;
  }

  if (page.pagePath.includes("trade")) {
    return AnalyticsPageType.trade;
  }

  if (page.pagePath.includes("parts")) {
    return AnalyticsPageType.parts;
  }

  return AnalyticsPageType.custom;
}

function convertVehicleType(vehicleType: VehicleType): string {
  return vehicleType === VehicleType.NEW
    ? "new"
    : vehicleType === VehicleType.USED
      ? "used"
      : vehicleType === VehicleType.CERTIFIED
        ? "cpo"
        : "unknown";
}

export function createAnalyticsDataFromVehicle(vehicle?: Vehicle): any {
  if (vehicle) {
    return {
      item_id: vehicle.vin,
      item_number: vehicle.stock,
      item_price: vehicle.type === VehicleType.NEW ? vehicle.msrp : vehicle.price,
      item_condition: convertVehicleType(vehicle.type),
      item_year: vehicle.year,
      item_make: vehicle.make,
      item_model: vehicle.model,
      item_variant: vehicle.trim || "",
      item_color: vehicle.color || "",
      item_type: vehicle.body?.toLowerCase() || "",
      item_fuel_type: vehicle.fuelType?.toLowerCase() || "",
      item_inventory_date: vehicle.lotDate ? new Date(vehicle.lotDate).toLocaleDateString(defaultLocale) : "",
    };
  }
  return {
    item_id: "",
    item_number: "",
    item_price: "",
    item_condition: "",
    item_year: "",
    item_make: "",
    item_model: "",
    item_variant: "",
    item_color: "",
    item_type: "",
    item_fuel_type: "",
    item_inventory_date: "",
  };
}

export function getButtonActionResult(button: BaseAction): string {
  if (button.routeType === RouteType.POPUP) {
    return "popup";
  } else if (button.routeType === RouteType.NEW_WINDOW) {
    return "open";
  } else {
    return "redirect";
  }
}

export interface LeadDates {
  salesLeadDate?: Date;
  serviceLeadDate?: Date;
  partsLeadDate?: Date;
}

export function loadLeadDates(): LeadDates {
  const result: LeadDates = {};
  if (typeof Window !== "undefined") {
    const salesLeadDate = window.localStorage.getItem(SALES_LEAD_DATE_CONFIG);
    const serviceLeadDate = window.localStorage.getItem(SERVICE_LEAD_DATE_CONFIG);
    const partsLeadDate = window.localStorage.getItem(PARTS_LEAD_DATE_CONFIG);
    if (salesLeadDate) {
      result.salesLeadDate = new Date(salesLeadDate);
    }
    if (serviceLeadDate) {
      result.serviceLeadDate = new Date(serviceLeadDate);
    }
    if (partsLeadDate) {
      result.partsLeadDate = new Date(partsLeadDate);
    }
  }

  return result;
}

export function fillAscDataLayer(
  newModels: ModelCount[],
  vehicles: Vehicle[],
  pageType: AnalyticsPageType,
  language: string,
  dealerName: string,
  createAscDataLayer?: boolean,
) {
  window.addEventListener("message", (event) => {
    const data = event.data;
    if (data.type === ANALITYCS_MESSAGE) {
      handleAnaliticsData(data.data);
    }
  });
  if (createAscDataLayer && !window.asc_datalayer) {
    const brand = newModels.length ? newModels[0].make : "";
    const dataLayer: any = {
      store_name: dealerName,
      oem_brand: brand,
      affiliation: "runmylease",
      language: language,
      currency: "usd",
      page_type: pageType,
      items: vehicles.map((vehicle) => createAnalyticsDataFromVehicle(vehicle)),
      events: [],
      ...loadLeadDates(),
    };

    window.asc_datalayer = dataLayer;
  }
}
